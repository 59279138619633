import React from 'react'
import {Link} from "gatsby"

import BarsIcon from '../images/icons/fa-bars.svg'
import face from '../images/layout/face.jpg'

export default class Header extends React.Component {

  render() {
    return (
      <div className="header">
        <div className="words">
          <h1>Jon Ellis</h1>
          <p>The musings of an "engineer"</p>
        </div>
        <div className="photo">
          <img src={ face } alt="Jon Ellis" width="100" height="100" />
        </div>
        <Link to="/menu" className="burger" onClick={ this.props.onBurgerClick }>
          <BarsIcon />
        </Link>
      </div>
    )
  }

}
