import React from "react"
import { MDXProvider } from '@mdx-js/react'

import SEO from './seo'
import BackgroundImage from './background-image'
import Menu from './menu'
import Header from './header'
import BlogPage from './blog-page'
import Image from './image'

import 'normalize.css/normalize.css'
import "../styles/styles.scss"

const shortcodes = {
  Image
}

export default class Layout extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false
    }

    this.pageContentRef = React.createRef()
  }

  menuToggle = (e) => {
    e.preventDefault();

    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen
    })
  }

  hideMenu = (e) => {
    this.setState({
      mobileMenuOpen: false
    })
  }

  renderPage() {
    if (!this.props.path.startsWith('/blog/')) {
      return this.props.children
    }

    return (
      <BlogPage { ...this.props } />
    )
  }

  componentDidUpdate() {
    this.pageContentRef.current.scrollTop = 0
  }

  render() {
    let frontmatter = this.props.pageContext.frontmatter
    let layoutClass = frontmatter.layoutClass ? `layout-${frontmatter.layoutClass}` : ''
    let containerClass = 'container-' + (frontmatter.pageType ? frontmatter.pageType : 'page')
    let showMenuClass = this.state.mobileMenuOpen ? 'show-menu' : ''

    let overlay = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="menu-overlay" onClick={ this.menuToggle } />
    )

    return (
      <>
        <SEO title={ frontmatter.title } />
        <div className={ `layout ${layoutClass}` }>
          <BackgroundImage imageName={ frontmatter.backgroundImage } />
          <div className={ `container ${containerClass} ${showMenuClass}` }>
            <Menu onItemClicked={ this.hideMenu } />
            <div className="main">
              <Header onBurgerClick={ this.menuToggle } />
              <div className="content">
                <div ref={ this.pageContentRef } className="content-inner">
                  <h1 className="title">{ frontmatter.title }</h1>
                  <MDXProvider components={shortcodes}>
                    { this.renderPage() }
                  </MDXProvider>
                </div>
              </div>
              { overlay }
            </div>
          </div>
        </div>
      </>
    )
  }

}
