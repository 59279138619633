import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import CameraRetro from '../images/icons/fa-camera-retro.svg'

function findImageByPath(images, path) {
  for (let image of images) {
    if (image.relativePath === path) {
      return image
    }
  }

  return false
}


class Image extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      openLightbox: false
    }
  }

  toggleLightbox = (e) => {
    e.preventDefault()

    this.setState({
      openLightbox: !this.state.openLightbox
    })
  }

  getGatsbyImageProps() {
    let gatsbyImageProps = {}

    let acceptedProps = [
      'alt', 'title', 'style', 'imgStyle', 'placeholderStyle', 'placeholderClassName', 'backgroundColor',
      'onLoad', 'onStartLoad', 'onError', 'Tag', 'objectFit', 'objectPosition', 'loading', 'draggable', 'itemProp'
    ]

    for (let [key, value] of Object.entries(this.props)) {
      if (acceptedProps.includes(key)) {
        gatsbyImageProps[key] = value
      }
    }

    return gatsbyImageProps
  }

  renderImage(size, extraProps = {}) {
    let defaultStyle = {
      maxWidth: this.props.image[size].fluid.presentationWidth
    }

    let props = Object.assign({}, this.getGatsbyImageProps(), extraProps)

    props.style = Object.assign({}, defaultStyle, props.style || {})

    return (
      <Img className={ this.props.imageClassName } fluid={ this.props.image[size].fluid } { ...props } />
    )
  }

  renderLightbox() {
    if (!this.state.openLightbox) {
      return (
        <div className="image-lightbox" />
      )
    }

    let lightbox = this.renderImage('full', {
      className: 'lightbox-image',
      imgStyle: {
        objectFit: 'contain'
      }
    })

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="image-lightbox" onClick={ this.toggleLightbox }>
        { lightbox }
      </div>
    )
  }

  render() {
    let classes = ['image-wrapper']

    if (this.props.className) {
      classes.push(this.props.className)
    }

    if (this.state.openLightbox) {
      classes.push('lightbox-open')
    }

    if (this.props.padded) {
      classes.push('padded')
    }

    if (this.props.noLightbox) {
      return (
        <span className={ classes.join(' ') }>
          { this.renderImage('thumb') }
        </span>
      )
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <a href={ this.props.image.publicURL } className={ classes.join(' ') } onClick={ this.toggleLightbox }>
        { this.renderImage('thumb') }
        { this.renderLightbox() }
      </a>
    )
  }

}



export default (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {
        sourceInstanceName: {eq: "images"}
        relativePath: {glob: "{backgrounds,blog,drawings}/**/*"}}
      ) {
        nodes {
          id
          relativePath
          publicURL
          thumb: childImageSharp {
            fluid(maxWidth: 990) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
          full: childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  `)

  let images = data.allFile.nodes
  let image = findImageByPath(images, props.path)

  if (!image) {
    let imgStyle = { color: '#ccc' }
    let classes = ['image-wrapper']

    if (props.className) {
      classes.push(props.className)
    }

    if (props.imgStyle) {
      imgStyle = Object.assign({}, imgStyle, props.imgStyle)
    }

    return (
      <span className={ classes.join(' ') }>
        <div className="gatsby-image-wrapper image-placeholder">
          <CameraRetro className={ props.imageClassName } style={ imgStyle } />
        </div>
      </span>
    )
  }

  return (
    <Image image={ image } { ...props } />
  )
}
