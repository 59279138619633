import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import moment from 'moment'

import Image from './image'

const query = graphql`
  query {
    allMdx(
      filter: {
        frontmatter: {
          path: {
            glob: "/blog/*"
          },
          published: {
            ne: null
          }
        }
      },
      sort: {
        fields: frontmatter___published,
        order: DESC
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            path
            published
            thumbnail
          }
        }
      }
    }
  }
`

function render(data) {
  let posts = data.allMdx.edges.map((edge) => {
    let post = edge.node
    let date = moment(post.frontmatter.published)
    let dateHtml = "Unknown published date"

    if (date.isValid()) {
      let relativeTime = ""
      if (date.isBefore()) {
        relativeTime = date.fromNow()
      }

      dateHtml = (
        <>
          <span>Published { date.format('MMMM Do YYYY') }</span>
          <span className="js-modify relative-time"> - { relativeTime }</span>
        </>
      )
    }

    return (
      <div className="blog-list-item" key={ post.id }>
        <Link className="blog-list-image" to={ post.frontmatter.path }>
          <Image noLightbox={ true } alt={ post.frontmatter.title } path={ post.frontmatter.thumbnail } />
        </Link>
        <div className="blog-list-text">
          <h2 className="blog-title">
            <Link to={ post.frontmatter.path }>
              { post.frontmatter.title }
            </Link>
          </h2>
          <p className="blog-published-date">
            { dateHtml }
          </p>
          <p className="blog-snippet">
            { post.excerpt }
            { ' ' }
            <Link to={ post.frontmatter.path }>read more</Link>
          </p>
        </div>
      </div>
    )
  })

  return (
    <div className="blog-list">{ posts }</div>
  )
}

export default () => (
  <StaticQuery
    query={ query }
    render={ render }
  />
)
