import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function findImageByName(images, name) {
  for (let image of images) {
    if (image.name === name) {
      return image
    }
  }

  return false
}

function renderImage(image) {
  return (
    <Img key={ image.name } className="background-image" fluid={ image.childImageSharp.fluid } draggable={ false } />
  )
}

function wrapImages(images) {
  return (
    <div className="background-image-container">
      { images }
    </div>
  )
}

let currentImage = null

export default ({ imageName }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {glob: "backgrounds/*"}}) {
        group(field: sourceInstanceName) {
          nodes {
            name
            childImageSharp {
              fluid(maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  let images = data.allFile.group[0].nodes
  let image = findImageByName(images, imageName)

  if (!image) {
    image = findImageByName(images, 'blackboard')
  }

  let displayedImages = []

  if (currentImage) {
    displayedImages.push(renderImage(currentImage))
  }

  if (currentImage === image) {
    return wrapImages(displayedImages)
  }

  currentImage = image

  displayedImages.push(renderImage(currentImage))

  return wrapImages(displayedImages)
}
