module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jon-ellis.co.uk","icon":"src/images/layout/profile.jpg","short_name":"jon-ellis.co.uk","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6cceaadcfcf48d30cadfcaab18f04b18"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/drone/src/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":3840}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-"}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/drone/src"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
