import React from "react"
import {Link} from "gatsby"

export default class Menu extends React.Component {

  constructor(props) {
    super(props);

    this.links = [
      {
        label: 'About me',
        uri: '/',
      }, {
        label: 'Contact me',
        uri: '/contact',
      }, {
        label: 'Employment History',
        uri: '/employment-history',
      }, {
        label: 'Qualifications',
        uri: '/qualifications',
      }, {
        label: 'Blog',
        uri: '/blog',
        partiallyActive: true
      }
    ];
  }

  itemClicked = (e) => {
    if (this.props.onItemClicked) {
      this.props.onItemClicked();
    }
  }

  renderLinks() {
    let links = [];

    for (let link of this.links) {
      links.push(
        <Link
          key={ link.uri }
          className="menu-item"
          to={ link.uri }
          activeClassName="active"
          onClick={ this.itemClicked }
          partiallyActive={ link.partiallyActive }
        >
          { link.label }
        </Link>
      );
    }

    return links;
  }

  render() {
    return (
      <div className="menu">
        { this.renderLinks() }
      </div>
    )
  }

}
