import React from "react"
import { Link, StaticQuery, graphql } from 'gatsby'
import moment from 'moment'

import Image from './image'

export default class BlogLinks extends React.Component {

  renderText(post) {
    let date = moment(post.frontmatter.published)
    let dateHtml = "Unknown published date"

    if (date.isValid()) {
      let relativeTime = ""
      if (date.isBefore()) {
        relativeTime = date.fromNow()
      }

      dateHtml = (
        <>
          <span>Published { date.format('MMMM Do YYYY') }</span>
          <span className="js-modify relative-time"> - { relativeTime }</span>
        </>
      )
    }

    return (
      <div className="blog-text">
        <p className="blog-title">
          { post.frontmatter.title }
        </p>
        <p className="blog-published-date">
          { dateHtml }
        </p>
      </div>
    )
  }

  renderImage(post) {
    return (
      <div className="blog-image">
        <Image noLightbox={ true } alt={ post.frontmatter.title } path={ post.frontmatter.thumbnail } />
      </div>
    )
  }

  renderNextLink(post) {
    let path = post.frontmatter.path

    return (
      <Link className="next-link" key={ path } to={ path }>
        { this.renderText(post) }
        { this.renderImage(post) }
      </Link>
    )
  }

  renderPreviousLink(post) {
    let path = post.frontmatter.path

    return (
      <Link className="previous-link" key={ path } to={ path }>
        { this.renderImage(post) }
        { this.renderText(post) }
      </Link>
    )
  }

  renderLinks = (data) => {
    let posts = data.allMdx.edges
    let currentIndex = null
    let links = []

    for (let [i, post] of posts.entries()) {
      if (post.node.frontmatter.path === this.props.path) {
        currentIndex = i
        break
      }
    }

    if (currentIndex === null) {
      return null
    }

    if (currentIndex > 0) {
      links.push(this.renderPreviousLink(posts[currentIndex - 1].node))
    } else {
      links.push(<span key="previous" className="previous-link" />)
    }

    if (currentIndex < posts.length - 1) {
      links.push(this.renderNextLink(posts[currentIndex + 1].node))
    } else {
      links.push(<span key="next" className="next-link" />)
    }

    if (links.length === 0) {
      return null
    }

    return (
      <div className="next-prev-links">
        { links }
      </div>
    )
  }

  render() {
    const postQuery = graphql`
      query {
        allMdx(
          filter: {
            frontmatter: {
              path: {
                glob: "/blog/*"
              },
              published: {
                ne: null
              }
            }
          },
          sort: {
            fields: frontmatter___published,
            order: ASC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                path
                thumbnail
                published
              }
            }
          }
        }
      }
    `

    return (
      <StaticQuery
        query={ postQuery }
        render={ this.renderLinks }
      />
    )
  }

}
