import "./src/styles/prism.scss";

export const onRouteUpdate = () => {
  var elements = document.getElementsByClassName('js-modify');
  // elements is updated as the matched elements no longer match after the js-modify class is removed

  while (elements.length) {
    elements.item(0).classList.remove('js-modify');
  }
}
