import React from 'react'
import moment from 'moment'

import BlogLinks from './blog-links'

export default class BlogPage extends React.Component {

  renderTags() {
    let tags = this.props.pageContext.frontmatter.tags || []

    if (tags.length === 0) {
      return null
    }

    let tagsHtml = tags.map((tag) => {
      return (
        <span key={ tag } className="tag">{ tag }</span>
      )
    })

    return (
      <div className="tags">
        <p>Tags: { tagsHtml }</p>
      </div>
    )
  }

  renderDate() {
    let published = this.props.pageContext.frontmatter.published
    if (!published) {
      return null
    }

    let date = moment(published)

    if (!date.isValid()) {
      return null
    }

    let relativeTime = ""
    if (date.isBefore()) {
      relativeTime = date.fromNow()
    }

    return (
      <p>Published { date.format('MMMM Do YYYY') }, <span className="js-modify relative-time">{ relativeTime }</span></p>
    )
  }

  render() {
    if (!this.props.pageContext.frontmatter.published && process.env.NODE_ENV !== 'development') {
      return (
        <p>This page is coming soon...</p>
      )
    }

    return (
      <>
        { this.renderDate() }
        { this.props.children }
        <BlogLinks path={ this.props.path } />
      </>
    )
  }

}
